import Button from "@components/Button";
import PopupWrapper from "@components/Popup/PopupWrapper";
import { logout } from "@reduxMain/actions/authActions";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "sonner";

const LogoutPopup = ({ isOpen, setIsOpen, onCancel }) => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        logout(dispatch).catch((err) => console.error(err));
    };

    return (
        <PopupWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="flex flex-col gap-10 px-8 py-7 bg-white rounded-lg shadow-lg">
                <h2 className="text-gray-800 text-center text-2xl font-semibold">
                    Are you sure you want to Logout?
                </h2>
                <div className="flex flex-col gap-4 justify-center">
                    <Button
                        onClick={handleLogout}
                        className="bg-primary hover:bg-primary-dark text-white font-semibold py-2 px-4 rounded-lg h-12 transition duration-300 ease-in-out"
                    >
                        Log out
                    </Button>
                    <Button
                        onClick={onCancel}
                        className="bg-white border-grey-300 text-gray-800 font-semibold py-2 px-4 rounded-lg h-12 transition duration-300 ease-in-out"
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </PopupWrapper>
    );
};

export default LogoutPopup;
