import React from "react";
import Icon from "@components/Icon";
import { ReactComponent as TooltipIcon } from "@public/TooltipIcon.svg";

const IconButton = ({
    as: Component = "button",
    onClick,
    tooltipLabel,
    tooltipDirection = "bottom",
    type,
    className = {},
    viewBox,
    src,
    disabled,
    ...props
}) => {
    const hoverClass = disabled ? "cursor-progress" : "hover:bg-grey-600 *:hover:fill-white";

    const tooltipPositions = {
        top: {
            containerClass: "absolute -top-8 inset-x-0",
            iconClass: "-bottom-2 rotate-180",
            labelPosition: "bottom-[-3px]",
        },
        bottom: {
            containerClass: "absolute -bottom-8 inset-x-0",
            iconClass: "-top-2",
            labelPosition: "top-[-3px]",
        },
    };

    const { containerClass, iconClass, labelPosition } =
        tooltipPositions[tooltipDirection] || tooltipPositions.bottom;

    return (
        <Component
            type="button"
            className={`group rounded-8 ${hoverClass} ${className?.button || ""}`}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            <div className="relative">
                {type === "image" ? (
                    <div className="w-10 h-10 flex justify-center items-center">
                        <img src={src} className="h-6 rounded-full" draggable={false} />
                    </div>
                ) : (
                    <Icon type={type} className={className} viewBox={viewBox} />
                )}
                {tooltipLabel && (
                    <div className={`${containerClass} flex justify-center items-center z-20`}>
                        <div
                            className={`relative ${labelPosition} hidden md:group-enabled:group-hover:block h-6 bg-grey-600 items-center rounded-4 text-base font-normal px-2 py-0.5 text-nowrap`}
                        >
                            {tooltipLabel}
                        </div>
                        <TooltipIcon
                            className={`absolute hidden md:group-enabled:group-hover:block ${iconClass} fill-grey-600`}
                        />
                    </div>
                )}
            </div>
        </Component>
    );
};

export default IconButton;
