import executor from "@common/utils/executor";
import AuthGuard from "@src/guards/auth.guard";
import OrganizationGuard from "./guards/organization.guard";
import { lazy } from "react";
import ModelPage from "@pages/ModelPage/index";
const ChooseOrg = lazy(() => import("@pages/ChooseOrg"));
const Dashboard = lazy(() => import("@pages/Dashboard"));
const Auth = lazy(() => import("@pages/Auth"));
const Organization = lazy(() => import("@pages/Organization"));

const routes = [
    {
        path: "/auth",
        component: Auth,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/choose-org",
        component: ChooseOrg,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/organization/:orgId/models/:slug",
        component: ModelPage,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/organization/:orgId/dashboard",
        exact: true,
        component: Dashboard,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "*",
        component: ChooseOrg,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
];

export default routes;
