import DynamicForm from "@common/components/DynamicFormComponent/index";
import InpaintingTool from "@common/components/InpaintTool";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { models } from "./model-config";
import { ArrowLeft } from "lucide-react";

const ModelPage = () => {
    const { slug } = useParams();
    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    };

    if (!models) {
        return <div className="text-text-primary">Loading...</div>;
    }

    const model = models.find((m) => m.slug === slug);

    if (!model) {
        return <div className="text-text-primary">Model not found</div>;
    }

    return (
        <div className="w-full mx-auto py-6 bg-bg-color px-4 w-full md:px-20">
            {model.slug === "inpainting" ? (
                <InpaintingTool model={model} onBack={handleBack} />
            ) : (
                <DynamicForm model={model} onBack={handleBack} />
            )}
        </div>
    );
};

export default ModelPage;
