import { combineReducers } from "redux";
import authReducer from "./reducers/auth.reducer";
import generationReducer from "./reducers/generation.reducer";
import orgReducer from "./reducers/organization.reducer";
import exploreImagesReducer from "./reducers/exploreImages.reducer";
import userAgentReducer from "./reducers/useragent.reducer";
import segmentReducer from "./reducers/segment.reducer";

const rootReducer = combineReducers({
    authenticationDetails: authReducer,
    segmentDetails: segmentReducer,
    generationDetails: generationReducer,
    organizationDetails: orgReducer,
    exploreImageDetails: exploreImagesReducer,
    userAgentDetails: userAgentReducer,
});

export default rootReducer;
