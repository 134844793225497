import Icon from "@common/components/Icon";
import SmallPopupBg from "@public/SmallPopupBg.png";
import React from "react";

const PopupWrapper = ({ children, isOpen, setIsOpen, showCloseButton }) => {
    const handleCloseClick = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div
                className={`fixed top-0 left-0 w-screen h-screen bg-grey-background bg-opacity-50 backdrop-blur-sm z-40 ${
                    isOpen ? "" : "hidden"
                }`}
                onClick={handleCloseClick}
            />
            <div
                className={`fixed top-[140px] flex items-start justify-center z-50 ${
                    isOpen ? "" : "hidden"
                }`}
            >
                <div className="bg-grey-900 rounded-12 shadow-lg w-92 select-none">
                    <div className="relative">
                        {showCloseButton && (
                            <button
                                className="cursor-pointer bg-grey-600 absolute rounded-full h-7 w-7 top-1 right-1 text-white text-xl align-middle flex justify-center items-center"
                                type="button"
                                onClick={handleCloseClick}
                            >
                                <Icon type="close" className={{ wrapper: "w-5 h-5 fill-white" }} />
                            </button>
                        )}
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default PopupWrapper;
