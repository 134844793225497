import React from "react";
import Icon from "../Icon";
import GenerationSettingsModal from "./GenerationSettingsModal";
import Button from "../Button";
import IconButton from "../IconButton";

const GenerationSettingsAndSubmission = ({
    generationSettingsIsOpen,
    setGenerationSettingsIsOpen,
    className,
    register,
    setValue,
    watch,
    _setFormSubmitClick,
    handleSubmit,
    isFormDisabled,
}) => {
    const toggleGenerationSettings = () => {
        setGenerationSettingsIsOpen(!generationSettingsIsOpen);
    };
    return (
        <div className={className}>
            <div className="flex flex-col gap-8 w-full">
                <div
                    className={`${
                        generationSettingsIsOpen ? "block" : "hidden"
                    } md:hidden max-h-[calc(100vh-66px-2px-13rem)] overscroll-none overflow-y-scroll`}
                >
                    <GenerationSettingsModal
                        isOpen={generationSettingsIsOpen}
                        setIsOpen={setGenerationSettingsIsOpen}
                        register={register}
                        setValue={setValue}
                        watch={watch}
                    />
                </div>
                <div className="flex flex-row-reverse md:flex-row justify-center items-stretch gap-2 w-full">
                    <div className="relative">
                        <IconButton
                            tooltipLabel="Settings"
                            type="settings"
                            className={{ wrapper: "w-12 h-12 fill-grey-300" }}
                            onClick={toggleGenerationSettings}
                            disabled={isFormDisabled}
                        />
                        <div className="hidden md:block">
                            <GenerationSettingsModal
                                isOpen={generationSettingsIsOpen}
                                setIsOpen={setGenerationSettingsIsOpen}
                                register={register}
                                setValue={setValue}
                                watch={watch}
                            />
                        </div>
                    </div>
                    <Button
                        className="w-full md:w-48 md:flex-none"
                        size="default"
                        testId="generate-btn"
                        onClick={() => {
                            _setFormSubmitClick(true);
                            handleSubmit();
                        }}
                        disabled={isFormDisabled}
                        isLoading={isFormDisabled}
                    >
                        Generate
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default GenerationSettingsAndSubmission;
