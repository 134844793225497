import URLS from "@utils/apiUrls";
import ApiService from "./api.service";
import retry from "async-retry";

const GenerationService = {
    startGeneration(formData, orgId) {
        let form = new FormData();
        const excludedKeys = [];

        for (const key in formData) {
            if (!excludedKeys.length || !excludedKeys.includes(key)) {
                form.append(key, formData[key]);
            }
        }

        // Set the appropriate headers for multipart/form-data
        let axiosOptions = {
            data: form,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return ApiService.post(URLS.bharatdiffusionGenerate.replace(":orgId", orgId), axiosOptions);
    },

    checkGenerationStatus(orgId, generationId) {
        let axiosOptions = {};
        return retry(
            async (bail) => {
                try {
                    const response = await ApiService.get(
                        URLS.bharatdiffusionGenerationStatus
                            .replace(":orgId", orgId)
                            .replace(":generationId", generationId),
                        axiosOptions,
                    );

                    // This will trigger a retry
                    if (response.status === 200 && response.data.status !== "SUCCESS") {
                        return Promise.reject(response);
                    }
                    return response;
                } catch (err) {
                    // Don't retry for any errors
                    bail(err);
                }
            },
            {
                retries: 150,
                factor: 1,
                minTimeout: 2000,
            },
        );
    },

    createGeneration(formData, orgId) {
        let axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.createGeneration.replace(":orgId", orgId), axiosOptions);
    },

    getGenerationHistory(orgId, startIndex, stopIndex) {
        let axiosOptions = {};
        const queryParams = [];
        if (startIndex && stopIndex) {
            queryParams.push(`startIndex=${startIndex}`);
            queryParams.push(`stopIndex=${stopIndex}`);
        }
        return ApiService.get(
            `${URLS.getGenerationHistory}?${queryParams.join("&")}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    getGenerationById(id, orgId) {
        let axiosOptions = {};
        return ApiService.get(
            URLS.getGenerationById.replace(":orgId", orgId).replace(":id", id),
            axiosOptions,
        );
    },

    updateGenerationById(formData, id, orgId) {
        return ApiService.put(
            URLS.updateGenerationById.replace(":orgId", orgId).replace(":id", id),
            { data: formData },
        );
    },

    deleteGenerationById(id, orgId) {
        let axiosOptions = {};
        return ApiService.delete(
            URLS.deleteGenerationById.replace(":orgId", orgId).replace(":id", id),
            axiosOptions,
        );
    },
};

export default GenerationService;
