// import { docLink } from "@common/utils/common-utils";

export const BG_SESSION_COOKIE_NAME = "bg.session";

export const THUMBNAIL_FILE_FORMAT_MAX_SIZE = 6;

export const ANONYMOUS_ID_PARAM_KEY = "anonymous_id";
export const SEGMENT_ANONYMOUS_ID_KEY = "ajs_anonymous_id";

export const mergeTransformation = "merge";
export const transformationOperation = "transformation";

export const CUSTOM_TYPE = "custom";
export const SKIP_CAPTCHA_PREFIX = "skipCode:";
export const ENTERPRISE_CALENDLY_LINK =
    "https://calendly.com/aafiya_patel/pixelbin-io-platform-demo?utm_source=EQ_Pixelbin_payment&utm_medium=Pixelbin&utm_campaign=Book+A+Demo";

export const ENTERPRISE_CALENDLY_ERASE_LINK =
    "https://calendly.com/aafiya_patel/erase-bg-product-overview";

export const ENTERPRISE_CALENDLY_UPSCALE_LINK =
    "https://calendly.com/aafiya_patel/upscale-media-product-overview";

export const ENTERPRISE_CALENDLY_WATERMARK_LINK =
    "https://calendly.com/aafiya_patel/watermark-remover-product-overview";

export const PIXELBIN_STORAGE_TYPE = "pixelbin-storage";
export const ALLOWED_DATASOURCES = [
    "pixelbin-storage",
    "google-cloud-storage",
    "s3",
    "firebase-storage",
    "dropbox",
    "azure-blob-storage",
];
export const ORG_ROUTE_REGEX = /^\/organization\/([^/]+)\/.*/;

// export const docs = {
//     url_structure: docLink("/url-structure/"),
//     presets: docLink("/presets/"),
// };

export const BULK_PLAYGROUND_UPLOAD_CONCURRENCY_LIMIT = 5;
export const BULK_PLAYGROUND_TRANSFORMATION_CONCURRENCY_LIMIT = 1;
export const BULK_PLAYGROUND_UPLOAD_SOURCE = "bulk-playground";
export const BULK_PLAYGROUND_PAGE_SIZE = 10;
export const BULK_PLAYGROUND_BATCH_SIZE_LIMIT = 50;

// Partner Stack Constants
const PS_STORAGE_KEY = "partnerStackQueryParams";
const PS_RENAME_MAP = { ps_xid: "xid", ps_partner_key: "partner_key" };
const PS_QUERY_PARAMS = ["xid", "partner_key", "ps_xid", "ps_partner_key"];
const PS_QUERY_STORAGE_TYPE = "local"; // local or session | local for localStorage and session for session storage

export const PS_QUERY_OPTIONS = {
    storageKey: PS_STORAGE_KEY,
    storageType: PS_QUERY_STORAGE_TYPE,
    renameMap: PS_RENAME_MAP,
    include: PS_QUERY_PARAMS,
};

export const OPENAI_OAUTH_QUERY_OPTIONS = {
    storageKey: "openaiOauthQueryParams",
    storageType: "local",
    renameMap: [],
    include: ["state", "redirect_uri", "client_id", "scope"],
};

export const INVOICE_STATUSES = {
    OPEN: "open",
    DRAFT: "draft",
    PAID: "paid",
    VOID: "void",
    UNCOLLECTIBLE: "uncollectible",
    PROCESS_FAILED: "process_failed", // when invoice is paid but got error in process
};

export const propertySearchTag = "pixelbin_console";

export const LAST_CONSENT_APPROVAL_KEY_PREFIX = "consentStatus_";

export const cacheWarmupSupportedDatasources = ["google-cloud-storage"];

export const ASPECT_RATIOS = {
    square: { tailwind: "aspect-square", css: "1 / 1", label: "Square", style: "aspect-square" },
    portrait: {
        tailwind: "aspect-portrait",
        css: "9 / 16",
        label: "Portrait",
        style: "aspect-[7_/_9]",
    },
    landscape: {
        tailwind: "aspect-landscape",
        css: "16 / 9",
        label: "Landscape",
        style: "aspect-[25_/_18]",
    },
};

export const GENERATION_STYLES = [
    { label: "Realistic", value: "realistic" },
    { label: "Cinematic", value: "cinematic" },
    { label: "Cyberpunk", value: "cyberpunk" },
    { label: "Digital Art", value: "digitalart" },
    { label: "Fantasy", value: "fantasy" },
    { label: "Anime", value: "anime" },
];

export const IMAGE_COUNT_OPTIONS = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
];
