import React from "react";
import LoaderGif from "@public/loader.gif";

function BrandLoaderIcon({ width = "75px", ...props }) {
    return (
        <img
            data-testid="loader-icon"
            src={LoaderGif}
            className={`w-[${width}] h-[${width}]`}
            {...props}
        />
    );
}

export default BrandLoaderIcon;
