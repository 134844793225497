import { ShimmerProvider } from "@common/components/Shimmer/ShimmerProvider";
import { GenerationProvider } from "@components/GenerationForm/GenerationContext";
import Navbar from "@components/GlobalNavBar";
import PrivateRoute from "@components/PrivateRoute";
import ShimmerLoop from "@public/videos/ShimmerAnimation.mp4";
import React, { Suspense } from "react";
import { hot } from "react-hot-loader";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import useScriptsHooks from "./common/hooks/ScriptsHooks";
import routes from "./routes";

const App = () => {
    useScriptsHooks();
    const [authDetails] = useSelector((state) => [state.authenticationDetails]);
    console.log("🚀 ~ App ~ authDetails:", authDetails);
    const [{ organization }] = useSelector((state) => [state.organizationDetails]);
    console.log("🚀 ~ App ~ organization:", organization);

    return (
        <BrowserRouter>
            <Suspense fallback={<></>}>
                <GenerationProvider>
                    <ShimmerProvider src={ShimmerLoop}>
                        <Navbar user={authDetails.user} organization={organization} />
                        <div className="w-screen h-[calc(100vh_-_67px)]">
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route
                                        path={route.path}
                                        exact={route.exact}
                                        key={index}
                                        render={(props) => (
                                            <PrivateRoute
                                                {...props}
                                                component={route.component}
                                                guard={route.guard}
                                            />
                                        )}
                                    />
                                ))}
                            </Switch>
                        </div>
                    </ShimmerProvider>
                </GenerationProvider>
            </Suspense>
        </BrowserRouter>
    );
};

export default hot(module)(App);
