import _ from "lodash";
import GenerationService from "@src/services/generation.service";

export const GenerationAction = {
    GenerationStartSuccess: "GENERATION_START_SUCCESS",
    GenerationPending: "GENERATION_PENDING",
    GetGenerationHistory: "GET_GENERATION_HISTORY",
    GenerationDetailsSetSuccess: "GENERATION_DETAILS_SET_SUCCESS",
    GenerationUpdate: "GENERATION_UPDATE",
    GenerationDeleteSuccess: "GENERATION_DELETE_SUCCESS",
};

export const startGeneration = (orgId, formData, dispatch) => {
    return new Promise((resolve, reject) => {
        GenerationService.startGeneration(formData, orgId)
            .then(async (res) => {
                if (res.data.status === "ACCEPTED") {
                    await GenerationService.createGeneration(
                        {
                            generationId: res.data._id,
                            imageUrls: res.data.output,
                            orgId,
                            prompt: res.data.input.prompt,
                            seed: res.data.input.seed,
                            generationStyle: res.data.input.generation_style,
                            aspectRatio: res.data.input.aspect_ratio,
                            outputImageCount: res.data.input.num_images_per_prompt,
                            guidanceScale: res.data.input.guidance_scale,
                            useDeepcache: res.data.input.use_deepcache,
                            inferenceSteps: res.data.input.num_inference_steps,
                            jobStatus: res.data.status,
                        },
                        orgId,
                    ).then((creationRes) => {
                        dispatch({
                            type: GenerationAction.GenerationStartSuccess,
                            payload: { ...creationRes.data.generation },
                        });

                        resolve(creationRes);
                    });
                } else {
                    console.error("genration job not accepted");
                    reject(res);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const checkGenerationStatus = (orgId, id, generationId, dispatch) => {
    return new Promise((resolve, reject) => {
        GenerationService.checkGenerationStatus(orgId, generationId)
            .then(async (res) => {
                await GenerationService.updateGenerationById(
                    {
                        imageUrls: res.data.output,
                        jobStatus: res.data.status,
                    },
                    id,
                    orgId,
                ).then((updateRes) => {
                    dispatch({
                        type: GenerationAction.GenerationUpdate,
                        payload: { ...updateRes.data.generation },
                    });

                    resolve(updateRes);
                });
            })
            .catch((err) => {
                if (err?.data?.status === "RUNNING") {
                    dispatch({
                        type: GenerationAction.GenerationUpdate,
                        payload: { ...err.data.generation },
                    });
                } else {
                    console.error("generation check failed", err);
                }
                reject(err);
            });
    });
};

export const deleteGeneration = (generationId, orgId, dispatch) => {
    return new Promise((resolve, reject) => {
        GenerationService.deleteGenerationById(generationId, orgId)
            .then((res) => {
                dispatch({
                    type: GenerationAction.GenerationDeleteSuccess,
                    payload: generationId,
                });

                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getGenerationHistory = (orgId, startIndex, stopIndex, dispatch) => {
    return new Promise((resolve, reject) => {
        GenerationService.getGenerationHistory(orgId, startIndex, stopIndex)
            .then((res) => {
                dispatch({
                    type: GenerationAction.GetGenerationHistory,
                    payload: { ...res.data },
                });
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getGenerationById = (generationId, orgId, dispatch) => {
    return new Promise((resolve, reject) => {
        GenerationService.getGenerationById(generationId, orgId)
            .then((res) => {
                dispatch({
                    type: GenerationAction.GenerationDetailsSetSuccess,
                    payload: { ...res.data },
                });
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const clearGenerationDetails = (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: GenerationAction.GenerationDetailsSetSuccess,
                payload: {},
            });
            resolve("Generation details cleared");
        } catch (err) {
            reject("Generation details not cleared");
        }
    });
};
