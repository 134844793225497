import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import BrandLoaderIcon from "./BrandLoaderIcon";
import _ from "lodash";
import { ANONYMOUS_ID_PARAM_KEY, PS_QUERY_OPTIONS } from "../constants/constants";
import { setPublicPropAnonymousId, setUtmSource } from "@common/utils/common-utils";
import useStoreQueryParams from "@common/hooks/useStoreQueryParams";
import useSessionStorageFromQuery from "../hooks/useSessionStorageFromQuery";

const PrivateRoute = ({ component: Component, guard, path }) => {
    useStoreQueryParams(PS_QUERY_OPTIONS);
    useSessionStorageFromQuery(["redirectToFreePropertyFlow"]);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoaderVisible, setIsLoaderVisible] = useState(true);
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    useEffect(() => {
        if (search) {
            const _utmSource = query.get("utm_source");
            console.log("_utmSource", _utmSource);
            const _publicPropAnonymousId = query.get(ANONYMOUS_ID_PARAM_KEY);
            console.log("_publicPropAnonymousId", _publicPropAnonymousId);
            setUtmSource(_utmSource);
            setPublicPropAnonymousId(_publicPropAnonymousId);
        }
    }, [search]);

    useEffect(() => {
        if (window.analytics)
            window.analytics?.page(
                _.pick(location, "path", "referrer", "search", "title", "url", "state"),
            );
        if (typeof window !== "undefined" && window.posthog) window.posthog?.capture("$pageview");
    }, [location]);

    useEffect(async () => {
        const value = await guard();
        // setRedirectTo(value);
        if (typeof value === "object") {
            history.push(value.location + value.search);
        } else {
            if (value) {
                history.push(value);
            }
        }
        setIsLoaderVisible(false);
    }, []);

    return isLoaderVisible ? (
        <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
            <BrandLoaderIcon className="h-20 w-20" />
        </div>
    ) : (
        <Component />
    );
};

export default PrivateRoute;
