import axios from "axios";
import environment from "@src/environment";

const BASE_URL = `${environment.API_MAIN_DOMAIN}/service/panel/transformation/v1.0/org/:orgID/predictions`;

// endpoint  = /generateSmallVariation/generate
export const submitJob = async (endpoint, orgId, formData) => {
    try {
        const URL = BASE_URL.replace(":orgID", orgId).concat(endpoint);

        const response = await axios.post(URL, formData);

        return response.data;
    } catch (error) {
        if (error.response) {
            console.error("Error response from API:", error.response.data);
            throw new Error(
                `API call failed with status ${error.response.status}: ${
                    error.response.data.message || error.message
                }`,
            );
        } else {
            console.error("Error during API call:", error.message);
            throw error;
        }
    }
};

export const getJobStatus = async (orgId, jobId) => {
    try {
        const URL = BASE_URL.replace(":orgID", orgId).concat("/", jobId);
        const response = await axios.get(URL);
        return response.data;
    } catch (error) {
        console.error("Error getting job status:", error);
        throw error;
    }
};

export const pollJobStatus = async (
    jobId,
    orgId,
    onSuccess,
    onError,
    maxAttempts = 120,
    interval = 2000,
) => {
    let attempts = 0;

    const poll = async () => {
        try {
            const result = await getJobStatus(orgId, jobId);
            if (result.status === "SUCCESS") {
                onSuccess(result);
            } else if (result.status === "FAILURE") {
                onError(result);
            } else if (attempts < maxAttempts) {
                attempts++;
                setTimeout(poll, interval);
            } else {
                onError({ error: "Max polling attempts reached" });
            }
        } catch (error) {
            onError(error);
        }
    };

    poll();
};

export const formatResponse = (response) => {
    if (Array.isArray(response)) {
        return response.join("\n");
    } else if (typeof response === "string") {
        return response;
    } else {
        return JSON.stringify(response, null, 2);
    }
};
