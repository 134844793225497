import { camelToPascal } from "@common/utils/common-utils";
import React from "react";

// Automatically import all SVGs as React components
const iconsContext = require.context("@public", false, /\.svg$/);
const icons = iconsContext.keys().reduce((acc, key) => {
    const componentName = key
        .replace(/(\.\/|\.svg)/g, "") // Remove './' and '.svg'
        .replace(/-./g, (match) => match[1].toUpperCase()) // Convert kebab-case to camelCase
        .replace(/^(.)/, (match) => match.toUpperCase()); // Convert first letter to uppercase
    acc[componentName] = iconsContext(key).ReactComponent;
    return acc;
}, {});

const Icon = ({ type, className, viewBox }) => {
    const iconName = camelToPascal(type) + "Icon";
    const IconComponent = icons[iconName];

    if (!IconComponent) {
        return <div className="bg-red-700">Invalid Icon Name</div>;
    }

    return (
        <span className={`flex justify-center items-center ${className?.wrapper ?? ""}`}>
            <IconComponent className={className?.icon ?? ""} viewBox={viewBox ?? "0 0 24 24"} />
        </span>
    );
};

export default Icon;
