import React, { createContext, useEffect, useRef, useState } from "react";

export const ShimmerContext = createContext(null);

export const ShimmerProvider = ({ src, children }) => {
    const videoRef = useRef(null);
    const [blobUrl, setBlobUrl] = useState(null);

    useEffect(() => {
        // Fetch the video only once and convert it to a blob URL
        const fetchVideo = async () => {
            const response = await fetch(src);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob); // Create a blob URL from the video
            setBlobUrl(url);
        };

        fetchVideo();

        return () => {
            // Cleanup the blob URL when the component is unmounted
            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);
            }
        };
    }, [src]);

    return <ShimmerContext.Provider value={blobUrl}>{children}</ShimmerContext.Provider>;
};
