export const models = [
    {
        name: "Small Variation Generator",
        icon: "Sparkles",
        slug: "small-variation-generator",
        status: "new",
        endpoint: "/smallVariationGenerator/generate",
        background:
            "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__fashiohub_pixelbin__/change-the-hairstyle-of-a-woman-portrait_(1).webp",
        fields: [
            {
                name: "input.image",
                label: "Image File",
                type: "file",
                width: "full",
                required: true,
            },
            {
                name: "input.prompt",
                label: "Prompt",
                type: "textarea",
                width: "full",
                required: true,
                maxLength: 255,
                description: "Prompt for image variation",
            },
            {
                name: "input.strength",
                label: "Variation Strength",
                type: "number",
                required: false,
                default: 1,
                min: 0,
                max: 1,
                step: 0.01,
                description: "Strength of the variation to apply to the image",
            },
            {
                name: "input.controlnet_scale",
                label: "ControlNet Scale",
                type: "number",
                required: false,
                default: 0.75,
                min: 0,
                max: 1,
                step: 0.01,
                description: "ControlNet scale to control the image's silhouette",
            },
            {
                name: "input.guidance_scale",
                label: "Guidance Scale",
                type: "number",
                required: false,
                default: 3.5,
                min: 0,
                max: 12,
                step: 0.1,
                description: "Weightage of the prompt in the variation generation",
            },
            {
                name: "input.num_inference_steps",
                label: "Number of Inference Steps",
                type: "number",
                required: false,
                default: 20,
                min: 1,
                description: "Number of steps for inference during variation generation",
            },
            {
                name: "input.seed",
                label: "Seed",
                type: "number",
                required: false,
                default: 92,
                min: 1,
                max: 1e32,
                description: "Random seed for reproducibility",
            },
        ],
    },
    {
        name: "Large Variation Generator",
        icon: "Sparkles",
        slug: "large-variation-generator",
        status: "new",
        endpoint: "/largeVariationGenerator/generate",
        background:
            "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__fashiohub_pixelbin__/change-the-hairstyle-of-a-woman-portrait_(1).webp",
        fields: [
            {
                name: "input.image",
                label: "Image File",
                type: "file",
                width: "full",
                required: true,
            },
            {
                name: "input.prompt",
                label: "Prompt",
                type: "textarea",
                width: "full",
                required: true,
                maxLength: 255,
                description: "Prompt for image variation",
            },
            {
                name: "input.num_images_per_prompt",
                label: "Number of Images",
                type: "number",
                required: false,
                default: 1,
                min: 1,
                max: 2,
            },
            {
                name: "input.ip_adapter_scale",
                label: "IP Adapter Scale",
                type: "number",
                required: false,
                default: 0.5,
            },
            {
                name: "input.controlnet_scale",
                label: "ControlNet Scale",
                type: "number",
                required: false,
                default: 0.5,
                min: 0,
                max: 1,
                step: 0.01,
                description: "ControlNet scale to control the image's silhouette",
            },
            {
                name: "input.guidance_scale",
                label: "Guidance Scale",
                type: "number",
                required: false,
                default: 7,
                min: 0,
                max: 12,
                step: 0.1,
                description: "Weightage of the prompt in the variation generation",
            },
            {
                name: "input.num_inference_steps",
                label: "Number of Inference Steps",
                type: "number",
                required: false,
                default: 10,
                min: 1,
                description: "Number of steps for inference during variation generation",
            },
            {
                name: "input.seed",
                label: "Seed",
                type: "number",
                required: false,
                default: 92,
                min: 1,
                max: 1e32,
                description: "Random seed for reproducibility",
            },
        ],
    },
    {
        name: "Cloth Segmentation",
        icon: "Scissors",
        slug: "cloth-segmentation",
        status: "new",
        endpoint: "/clothSegmentation/generate",
        background:
            "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__fashiohub_pixelbin__/https_3A_2F_2F40e507dd0272b7bb46d376a326e6cb3c.cdn.bubble.io_2Ff1722240248883x973999510499220100_2FAsset_252024_25401000x-100.jpg",
        fields: [
            {
                name: "input.image",
                label: "Image File",
                type: "file",
                width: "full",
                required: true,
            },
            {
                name: "input.cloth_type",
                label: "Cloth Type",
                type: "select",
                required: false,
                default: "fullwear",
                options: [
                    { value: "topwear", label: "Top Wear" },
                    { value: "bottomwear", label: "Bottom Wear" },
                    { value: "fullwear", label: "Full Wear" },
                ],
            },
        ],
    },
    {
        name: "Image Captioning",
        icon: "FileText",
        slug: "image-captioning",
        status: "new",
        endpoint: "/imageCaptioning/generate",
        background:
            "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__fashiohub_pixelbin__/hero-15b04c3269ec1bdbd60920f257e21732-zfvtOvXz0.png",
        fields: [
            {
                name: "input.image",
                label: "Image File",
                type: "file",
                width: "full",
                required: true,
            },
        ],
    },
    {
        name: "Seamless Tile",
        icon: "Grid",
        slug: "seamless-tile",
        status: "new",
        endpoint: "/seamlessTile/generate",
        background:
            "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__fashiohub_pixelbin__/engraving-hand-drawn-pattern-collection_23-2148918572.jpg",
        fields: [
            {
                name: "input.image",
                label: "Image File",
                type: "file",
                width: "full",
                required: true,
            },
            {
                name: "input.prompt",
                label: "Prompt",
                type: "textarea",
                width: "full",
                required: false,
                default: "",
            },
            {
                name: "input.num_images_per_prompt",
                label: "Number of Images",
                type: "number",
                required: false,
                default: 1,
                min: 1,
                max: 2,
            },
            {
                name: "input.padding",
                label: "Padding",
                type: "number",
                required: false,
                default: 0.15,
                min: 0,
                max: 1,
                step: 0.01,
            },
            {
                name: "input.seed",
                label: "Seed",
                type: "number",
                required: false,
                default: 92,
                min: 1,
                max: 1e32,
            },
        ],
    },
    {
        name: "Inpainting",
        icon: "Brush",
        slug: "inpainting",
        status: "new",
        endpoint: "/inpainting/generate",
        background:
            "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__fashiohub_pixelbin__/02-step-inpaint.png",
        fields: [
            {
                name: "input.image",
                label: "Image File",
                type: "file",
                width: "full",
                required: true,
            },
            {
                name: "input.mask_image",
                label: "Mask File",
                type: "canvas",
                width: "full",
                required: true,
            },
            {
                name: "input.prompt",
                label: "Prompt",
                type: "textarea",
                required: false,
                width: "full",
                default: "",
            },
            {
                name: "input.num_images_per_prompt",
                label: "Number of Images",
                type: "number",
                required: false,
                default: 1,
            },
            {
                name: "input.guidance_scale",
                label: "Guidance Scale",
                type: "number",
                required: false,
                default: 3.5,
                min: 0,
                max: 12,
                step: 0.1,
                description: "Weightage of the prompt in the variation generation",
            },
            {
                name: "input.num_inference_steps",
                label: "Number of Inference Steps",
                type: "number",
                required: false,
                default: 20,
                min: 1,
                description: "Number of steps for inference during variation generation",
            },
            {
                name: "input.strength",
                label: "Variation Strength",
                type: "number",
                required: false,
                default: 1,
                min: 0,
                max: 1,
                step: 0.01,
                description: "Strength of the variation to apply to the image",
            },
            {
                name: "input.mask_inflation",
                label: "Mask Inflation",
                type: "number",
                required: false,
                default: 0,
            },
            {
                name: "input.mask_blur",
                label: "Mask Blur",
                type: "number",
                required: false,
                default: 0,
            },
            {
                name: "input.seed",
                label: "Seed",
                type: "number",
                required: false,
                default: 92,
            },
        ],
    },
    {
        name: "Text Image Generator",
        icon: "Type",
        slug: "text-image-generator",
        status: "new",
        endpoint: "/textImageGenerator/generate",
        background:
            "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__fashiohub_pixelbin__/text-to-image-v2.webm",
        fields: [
            {
                name: "input.prompt",
                label: "Prompt",
                type: "textarea",
                required: true,
                width: "full",
                default: "",
            },
            {
                name: "input.num_images_per_prompt",
                label: "Number of Images",
                type: "number",
                required: false,
                default: 1,
                min: 1,
                max: 2,
            },
            {
                name: "input.seed",
                label: "Seed",
                type: "number",
                required: false,
                default: 92,
                min: 1,
                max: 1e32,
            },
        ],
    },
    {
        name: "Attribute Image Generator",
        icon: "Image",
        slug: "attribute-image-generator",
        status: "new",
        endpoint: "/attributeImageGenerator/generate",
        background:
            "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__fashiohub_pixelbin__/image-generator-freepik-3-v3.webp",
        fields: [
            {
                name: "input.prompt",
                label: "Prompt",
                type: "textarea",
                required: true,
                width: "full",
                default: "",
            },
            {
                name: "input.num_images_per_prompt",
                label: "Number of Images",
                type: "number",
                required: false,
                default: 1,
                min: 1,
                max: 2,
            },
            {
                name: "input.seed",
                label: "Seed",
                type: "number",
                required: false,
                default: 92,
                min: 1,
                max: 1e32,
            },
        ],
    },
    {
        name: "3D Print",
        icon: "Printer",
        slug: "print-3d",
        status: "new",
        endpoint: "/print3D/generate",
        background:
            "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__fashiohub_pixelbin__/https___40e507dd0272b7bb46d376a326e6cb3c.cdn.bubble.io_f1718042013585x482358132066792700_fabric-textile-to-fashion-design.jpg",
        fields: [
            {
                name: "input.image",
                label: "Image File",
                type: "file",
                width: "full",
                required: true,
            },
            {
                name: "input.apparel_type",
                label: "Apparel Type",
                type: "select",
                required: false,
                default: "Men Tshirt",
                options: [
                    { value: "Men Blazer", label: "Men Blazer" },
                    { value: "Men Jacket", label: "Men Jacket" },
                    { value: "Men Jeans", label: "Men Jeans" },
                    { value: "Men Pants", label: "Men Pants" },
                    { value: "Men Polo Shirts", label: "Men Polo Shirts" },
                    { value: "Men Shorts", label: "Men Shorts" },
                    { value: "Men Tshirt", label: "Men Tshirt" },
                    { value: "Men Full Sleeve Shirt", label: "Men Full Sleeve Shirt" },
                    { value: "Men Half Sleeve Shirt", label: "Men Half Sleeve Shirt" },
                    {
                        value: "Men Half Sleeve Shirt wo Pocket",
                        label: "Men Half Sleeve Shirt without Pocket",
                    },
                    { value: "Men Full Sleeve Tshirt", label: "Men Full Sleeve Tshirt" },
                    { value: "Men Half Sleeve Tshirt", label: "Men Half Sleeve Tshirt" },
                    { value: "Women Bodycon Dress", label: "Women Bodycon Dress" },
                    { value: "Women Crop Top Tshirt", label: "Women Crop Top Tshirt" },
                    {
                        value: "Women Full Sleeve Turtle",
                        label: "Women Full Sleeve Turtle",
                    },
                    { value: "Women Jeans", label: "Women Jeans" },
                    { value: "Women Joggers", label: "Women Joggers" },
                    { value: "Women Pleated Shorts", label: "Women Pleated Shorts" },
                    {
                        value: "Women Shirt Full Sleeves",
                        label: "Women Shirt Full Sleeves",
                    },
                    { value: "Women Short Blazer", label: "Women Short Blazer" },
                    { value: "Women Pants", label: "Women Pants" },
                    { value: "Women Dress", label: "Women Dress" },
                ],
            },
            {
                name: "input.print_scale",
                label: "Print Scale",
                type: "number",
                required: false,
                default: 0.5,
                min: 0,
                max: 1,
                step: 0.01,
            },
        ],
    },
    {
        name: "Attribute Caption Generator",
        icon: "Tag",
        slug: "attribute-caption-generator",
        status: "new",
        endpoint: "/attributeCaptionGenerator/generate",
        background:
            "https://cdn.pixelbin.io/v2/dummy-cloudname/original/__fashiohub_pixelbin__/hero-15b04c3269ec1bdbd60920f257e21732.png",
        fields: [
            {
                name: "input.image",
                label: "Image File",
                type: "file",
                width: "full",
                required: false,
            },
            {
                name: "input.print_details",
                label: "Print Details",
                type: "textarea",
                required: false,
                width: "full",
                default: "",
            },
            {
                name: "input.gender",
                label: "Gender",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Men", label: "Men" },
                    { value: "Women", label: "Women" },
                ],
            },
            {
                name: "input.clothing_type",
                label: "Clothing Type",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Nehru Jackets", label: "Nehru Jackets" },
                    { value: "Jackets", label: "Jackets" },
                    { value: "Tights", label: "Tights" },
                    { value: "Sweaters", label: "Sweaters" },
                    { value: "Dresses", label: "Dresses" },
                    { value: "Shirts", label: "Shirts" },
                    { value: "Blazers", label: "Blazers" },
                    { value: "Sarees", label: "Sarees" },
                    { value: "Leggings", label: "Leggings" },
                    { value: "Shorts", label: "Shorts" },
                    { value: "Palazzos", label: "Palazzos" },
                    { value: "Sweatshirts", label: "Sweatshirts" },
                    { value: "Tops", label: "Tops" },
                    { value: "Jumpsuit", label: "Jumpsuit" },
                    { value: "Ethnic Dresses", label: "Ethnic Dresses" },
                    { value: "Trousers", label: "Trousers" },
                    { value: "Nightdress", label: "Nightdress" },
                    { value: "Kurtis", label: "Kurtis" },
                    { value: "Skirts", label: "Skirts" },
                    { value: "Tunics", label: "Tunics" },
                    { value: "Tshirts", label: "Tshirts" },
                    { value: "Dupatta", label: "Dupatta" },
                    { value: "Lounge Pants", label: "Lounge Pants" },
                    { value: "Kurtas", label: "Kurtas" },
                ],
            },
            {
                name: "input.occasion",
                label: "Occasion",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Outdoor", label: "Outdoor" },
                    { value: "Ethnic", label: "Ethnic" },
                    { value: "Maternity", label: "Maternity" },
                    { value: "Casual", label: "Casual" },
                    { value: "Festive", label: "Festive" },
                    { value: "Work", label: "Work" },
                    { value: "Everyday", label: "Everyday" },
                    { value: "Fusion", label: "Fusion" },
                    { value: "Sports", label: "Sports" },
                    { value: "Party", label: "Party" },
                    { value: "Formal", label: "Formal" },
                    { value: "Daily", label: "Daily" },
                    { value: "Western", label: "Western" },
                    { value: "Semiformal", label: "Semiformal" },
                    { value: "Traditional", label: "Traditional" },
                ],
            },
            {
                name: "input.sleeve_length",
                label: "Sleeve Length",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Long Sleeves", label: "Long Sleeves" },
                    { value: "Short Sleeves", label: "Short Sleeves" },
                    { value: "Sleeveless", label: "Sleeveless" },
                    { value: "Three-Quarter Sleeves", label: "Three-Quarter Sleeves" },
                ],
            },
            {
                name: "input.sleeve_styling",
                label: "Sleeve Styling",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Cold-Shoulder Sleeves", label: "Cold-Shoulder Sleeves" },
                    { value: "Layered Sleeves", label: "Layered Sleeves" },
                    { value: "Cap Sleeves", label: "Cap Sleeves" },
                    { value: "Extended Sleeves", label: "Extended Sleeves" },
                    { value: "Drop-Shoulder Sleeves", label: "Drop-Shoulder Sleeves" },
                    { value: "Bishop Sleeves", label: "Bishop Sleeves" },
                    { value: "Cape Sleeves", label: "Cape Sleeves" },
                    { value: "Dolman Sleeves", label: "Dolman Sleeves" },
                    {
                        value: "Accordion Pleated Sleeves",
                        label: "Accordion Pleated Sleeves",
                    },
                    { value: "Slit Sleeves", label: "Slit Sleeves" },
                    { value: "Cuffed Sleeves", label: "Cuffed Sleeves" },
                    { value: "Flutter Sleeves", label: "Flutter Sleeves" },
                    { value: "Batwing Sleeves", label: "Batwing Sleeves" },
                    { value: "Raglan Sleeves", label: "Raglan Sleeves" },
                    { value: "Puff Sleeves", label: "Puff Sleeves" },
                    { value: "Doctor Sleeves", label: "Doctor Sleeves" },
                    { value: "Bell Sleeves", label: "Bell Sleeves" },
                    { value: "No Sleeves", label: "No Sleeves" },
                    { value: "Shoulder Straps", label: "Shoulder Straps" },
                    { value: "Flared Sleeves", label: "Flared Sleeves" },
                    { value: "Kimono Sleeves", label: "Kimono Sleeves" },
                    { value: "Roll-Up Sleeves", label: "Roll-Up Sleeves" },
                    { value: "Regular Sleeves", label: "Regular Sleeves" },
                ],
            },
            {
                name: "input.neck",
                label: "Neck",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Band Collar", label: "Band Collar" },
                    { value: "Polo Collar", label: "Polo Collar" },
                    { value: "Scoop Neck", label: "Scoop Neck" },
                    { value: "Peter Pan Collar", label: "Peter Pan Collar" },
                    { value: "Halter Neck", label: "Halter Neck" },
                    {
                        value: "Above the Keyboard Collar",
                        label: "Above the Keyboard Collar",
                    },
                    { value: "Cowl Neck", label: "Cowl Neck" },
                    { value: "Mock Neck", label: "Mock Neck" },
                    { value: "Sweetheart Neck", label: "Sweetheart Neck" },
                    { value: "Lapel Collar", label: "Lapel Collar" },
                    { value: "High Neck", label: "High Neck" },
                    { value: "Keyhole Neck", label: "Keyhole Neck" },
                    { value: "Asymmetric Neck", label: "Asymmetric Neck" },
                    { value: "Shirt Collar", label: "Shirt Collar" },
                    { value: "Mock Collar", label: "Mock Collar" },
                    { value: "Henley Neck", label: "Henley Neck" },
                    { value: "Shawl Neck", label: "Shawl Neck" },
                    { value: "Round Neck", label: "Round Neck" },
                    { value: "Turtle Neck", label: "Turtle Neck" },
                    { value: "Shawl Collar", label: "Shawl Collar" },
                    { value: "Off-Shoulder", label: "Off-Shoulder" },
                    { value: "Strapless", label: "Strapless" },
                    { value: "Stylised Neck", label: "Stylised Neck" },
                    { value: "One Shoulder", label: "One Shoulder" },
                    { value: "V-Neck", label: "V-Neck" },
                    { value: "Choker Neck", label: "Choker Neck" },
                    { value: "Mandarin Collar", label: "Mandarin Collar" },
                    { value: "Square Neck", label: "Square Neck" },
                    { value: "Hood", label: "Hood" },
                    { value: "Boat Neck", label: "Boat Neck" },
                    { value: "Racerback", label: "Racerback" },
                    { value: "Tie-Up Neck", label: "Tie-Up Neck" },
                    { value: "Shoulder Straps", label: "Shoulder Straps" },
                    { value: "U-Neck", label: "U-Neck" },
                ],
            },
            {
                name: "input.length",
                label: "Length",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Longline", label: "Longline" },
                    { value: "Above Knee", label: "Above Knee" },
                    { value: "Churidar Length", label: "Churidar Length" },
                    { value: "Regular", label: "Regular" },
                    { value: "Ankle Length", label: "Ankle Length" },
                    { value: "Three-Fourth Length", label: "Three-Fourth Length" },
                    { value: "Mini", label: "Mini" },
                    { value: "Crop", label: "Crop" },
                    { value: "Maxi", label: "Maxi" },
                    { value: "Below Knee", label: "Below Knee" },
                    { value: "Midi", label: "Midi" },
                    { value: "Knee Length", label: "Knee Length" },
                    { value: "Calf Length", label: "Calf Length" },
                    { value: "Cropped", label: "Cropped" },
                ],
            },
            {
                name: "input.color",
                label: "Color",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Red", label: "Red" },
                    { value: "Tan", label: "Tan" },
                    { value: "Beige", label: "Beige" },
                    { value: "Olive", label: "Olive" },
                    { value: "Grey", label: "Grey" },
                    { value: "Maroon", label: "Maroon" },
                    { value: "Orange", label: "Orange" },
                    { value: "Purple", label: "Purple" },
                    { value: "Khaki", label: "Khaki" },
                    { value: "Silver", label: "Silver" },
                    { value: "Off White", label: "Off White" },
                    { value: "Teal", label: "Teal" },
                    { value: "Cream", label: "Cream" },
                    { value: "Peach", label: "Peach" },
                    { value: "Blue", label: "Blue" },
                    { value: "White", label: "White" },
                    { value: "Violet", label: "Violet" },
                    { value: "Multi", label: "Multi" },
                    { value: "Brown", label: "Brown" },
                    { value: "Green", label: "Green" },
                    { value: "Gray", label: "Gray" },
                    { value: "Yellow", label: "Yellow" },
                    { value: "Gold", label: "Gold" },
                    { value: "Black", label: "Black" },
                    { value: "Steel", label: "Steel" },
                    { value: "Pink", label: "Pink" },
                ],
            },
            {
                name: "input.closure",
                label: "Closure",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Toggle", label: "Toggle" },
                    { value: "Button", label: "Button" },
                    { value: "Hook and Bar", label: "Hook and Bar" },
                    { value: "Zip", label: "Zip" },
                    { value: "Elasticated", label: "Elasticated" },
                    { value: "Drawstring", label: "Drawstring" },
                    { value: "Hook and Eye", label: "Hook and Eye" },
                    { value: "Hook", label: "Hook" },
                    { value: "Tie-Ups", label: "Tie-Ups" },
                    { value: "Front-Open", label: "Front-Open" },
                    { value: "Slip-On", label: "Slip-On" },
                    { value: "Half Zipper", label: "Half Zipper" },
                    { value: "Snap Button", label: "Snap Button" },
                    { value: "Concealed Zip", label: "Concealed Zip" },
                ],
            },
            {
                name: "input.collar",
                label: "Collar",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Band Collar", label: "Band Collar" },
                    { value: "Slim Collar", label: "Slim Collar" },
                    { value: "Wingtip Collar", label: "Wingtip Collar" },
                    { value: "Collarless", label: "Collarless" },
                    { value: "Cuban Collar", label: "Cuban Collar" },
                    { value: "Peter Pan Collar", label: "Peter Pan Collar" },
                    { value: "Hooded", label: "Hooded" },
                    { value: "Stand Collar", label: "Stand Collar" },
                    {
                        value: "Above the Keyboard Collar",
                        label: "Above the Keyboard Collar",
                    },
                    { value: "Lapel Collar", label: "Lapel Collar" },
                    { value: "Spread Collar", label: "Spread Collar" },
                    { value: "Club Collar", label: "Club Collar" },
                    { value: "Mock Collar", label: "Mock Collar" },
                    { value: "Double Collar", label: "Double Collar" },
                    { value: "Notched Lapel", label: "Notched Lapel" },
                    { value: "Shawl Collar", label: "Shawl Collar" },
                    { value: "Mandarin Collar", label: "Mandarin Collar" },
                    { value: "Hood", label: "Hood" },
                    { value: "Cutaway Collar", label: "Cutaway Collar" },
                    { value: "Built-Up Collar", label: "Built-Up Collar" },
                    { value: "Peaked Lapel", label: "Peaked Lapel" },
                    { value: "Button-Down Collar", label: "Button-Down Collar" },
                ],
            },
            {
                name: "input.waist_rise",
                label: "Waist Rise",
                type: "select",
                required: false,
                default: "NA",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "High-Rise", label: "High-Rise" },
                    { value: "Mid-Rise", label: "Mid-Rise" },
                    { value: "Low-Rise", label: "Low-Rise" },
                ],
            },
            {
                name: "input.visibility",
                label: "Visibility",
                type: "select",
                required: false,
                default: "Full",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Full", label: "Full" },
                    { value: "Partial", label: "Partial" },
                ],
            },
            {
                name: "input.orientation",
                label: "Orientation",
                type: "select",
                required: false,
                default: "Front",
                options: [
                    { value: "NA", label: "Not Applicable" },
                    { value: "Front", label: "Front" },
                    { value: "Back", label: "Back" },
                    { value: "Left", label: "Left" },
                    { value: "Right", label: "Right" },
                ],
            },
        ],
    },
];

export default models;
