import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import "react-image-lightbox/style.css";
import { Upload, ArrowLeft, Wand2, X, MoreVertical } from "lucide-react";
import { formatResponse, pollJobStatus, submitJob } from "@src/services/fashionhub.service";
import toast, { Toaster } from "react-hot-toast";
import { GLTFModel, AmbientLight, DirectionLight } from "react-3d-viewer";
import { useSelector } from "react-redux";

const ImageModal = ({ image, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="relative bg-white rounded-lg overflow-hidden max-w-4xl max-h-[90vh] w-full mx-4">
            <div className="absolute top-4 right-4 z-10">
                <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-white transition-colors"
                    aria-label="Close modal"
                >
                    <X size={24} />
                </button>
            </div>
            <div className="p-4">
                <img
                    src={image}
                    alt="Full size"
                    className="max-w-full max-h-[calc(90vh-2rem)] object-contain mx-auto"
                />
            </div>
        </div>
    </div>
);

const ImageActions = ({ image, index, onDownload, onCopyLink }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="relative">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="p-1 bg-white rounded-full hover:bg-gray-200 transition-colors"
            >
                <MoreVertical size={20} />
            </button>
            {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    <button
                        onClick={() => {
                            onDownload(image, `image_${index + 1}.jpg`);
                            setIsOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                        Download Image
                    </button>
                    <button
                        onClick={() => {
                            onCopyLink(image);
                            setIsOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                        Copy Image Link
                    </button>
                </div>
            )}
        </div>
    );
};

const DynamicForm = ({ model, onBack }) => {
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [files, setFiles] = useState({});
    const [progress, setProgress] = useState(0);
    const [validationErrors, setValidationErrors] = useState({});
    const [modalImage, setModalImage] = useState(null);
    const [animatedText, setAnimatedText] = useState("");
    const [resultImageLoading, setResultImageLoading] = useState({});
    const textRef = useRef(null);
    const orgData = useSelector((state) => state.organizationDetails.organization);
    const orgId = orgData._id;

    useEffect(() => {
        let timer;
        if (loading) {
            timer = setInterval(() => {
                setProgress((oldProgress) => {
                    let diff = oldProgress >= 50 ? 1 : 10;
                    if (oldProgress >= 50) {
                        diff = oldProgress >= 50 ? 1 : 10;
                    }
                    return Math.min(oldProgress + diff, 99);
                });
            }, 500);
        }
        return () => {
            clearInterval(timer);
            setProgress(0);
        };
    }, [loading]);

    useEffect(() => {
        if (result) {
            let textToAnimate = "";
            if (typeof result === "string") {
                try {
                    const parsedResult = JSON.parse(result);
                    textToAnimate = parsedResult.output || "";
                } catch (e) {
                    textToAnimate = result;
                }
            } else if (typeof result === "object" && result.output) {
                textToAnimate = result.output;
            }

            setAnimatedText("");

            let index = 0;
            const timer = setInterval(() => {
                if (index < textToAnimate.length) {
                    setAnimatedText((prev) => prev + textToAnimate[index]);
                    index++;
                } else {
                    clearInterval(timer);
                }
            }, 20);
            return () => clearInterval(timer);
        }
    }, [result]);

    useEffect(() => {
        if (textRef.current) {
            textRef.current.scrollTop = textRef.current.scrollHeight;
        }
    }, [animatedText]);

    const onDrop = useCallback((acceptedFiles, fieldName) => {
        setFiles((prev) => ({
            ...prev,
            [fieldName]: acceptedFiles[0],
        }));
        setValidationErrors((prev) => ({
            ...prev,
            [fieldName]: false,
        }));
    }, []);

    const validateFields = () => {
        const errors = {};

        // Utility function to check if the field value is empty
        const isFieldValueEmpty = (field) => {
            if (field.type === "file") {
                return !files[field.name]; // Check if the file input is empty
            }
            const fieldElement = document.getElementsByName(field.name)[0];
            return !fieldElement || !fieldElement.value; // Check if the non-file input is empty
        };

        // Loop through each field and validate
        model.fields.forEach((field) => {
            if (field.required && isFieldValueEmpty(field)) {
                errors[field.name] = "This field is required.";
            }
        });

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateFields()) {
            return;
        }

        setLoading(true);
        setError(null);
        setResult(null);
        setAnimatedText("");
        setResultImageLoading({});

        const formData = new FormData();
        let formJson = {};

        Object.entries(files).forEach(([fieldName, file]) => {
            formData.append(fieldName, file);
            formJson[fieldName] = file;
        });

        for (let field of model.fields) {
            const value = e.target[field.name].value;
            formData.append(field.name, value);

            if (field.type !== "file") {
                formJson[field.name] = value;
            }
        }

        try {
            const response = await submitJob(model.endpoint, orgId, formData);

            if (response._id) {
                pollJobStatus(
                    response._id,
                    orgId,
                    (res) => {
                        setProgress(100);
                        setTimeout(() => {
                            setResult(formatResponse(res));
                            setLoading(false);
                        }, 500);
                    },
                    (err) => {
                        setError(err);
                        setLoading(false);
                    },
                );
            } else {
                throw new Error("No request_id received from server");
            }
        } catch (err) {
            setError(err.response?.data || err.message);
            setLoading(false);
        }
    };

    const handleImageClick = (image) => {
        setModalImage(image);
    };

    const downloadImage = async (url, filename) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success(`Downloaded ${filename}`);
        } catch (err) {
            console.error("Download failed:", err);
            toast.error("Download failed");
        }
    };

    const copyImageLink = (url) => {
        navigator.clipboard
            .writeText(url)
            .then(() => {
                toast.success("Image link copied to clipboard");
            })
            .catch(() => {
                toast.error("Failed to copy image link");
            });
    };

    const downloadAllImages = async (images) => {
        let successCount = 0;
        for (let i = 0; i < images.length; i++) {
            try {
                await downloadImage(images[i], `image_${i + 1}.jpg`);
                successCount++;
            } catch (err) {
                console.error(`Failed to download image ${i + 1}:`, err);
            }
        }
        toast.success(`Downloaded ${successCount} out of ${images.length} images`);
    };

    const renderResponse = () => {
        if (!result) return null;

        let data;

        try {
            data = typeof result === "string" ? JSON.parse(result) : result;
        } catch (e) {
            console.error("Error parsing result:", e);
            return <div className="text-red-500">Invalid result format</div>;
        }

        const isUrl = (string) => {
            const urlPattern = new RegExp(
                "^(https?:\\/\\/)?" +
                    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                    "((\\d{1,3}\\.){3}\\d{1,3}))" +
                    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                    "(\\?[;&a-z\\d%_.~+=-]*)?" +
                    "(\\#[-a-z\\d_]*)?$",
                "i",
            );
            return !!urlPattern.test(string);
        };

        if (Array.isArray(data.output)) {
            // Separate URLs and plain text strings in the output array
            const urls = data.output.filter((item) => isUrl(item));
            const texts = data.output.filter((item) => !isUrl(item));

            return (
                <div className="flex flex-col gap-4">
                    {urls.length > 0 && (
                        <div className="image-outputs">{renderImageOutput(urls)}</div>
                    )}
                    {texts.length > 0 && (
                        <div className="text-outputs animate-fade-in text-lg font-medium text-center h-full flex items-center justify-center">
                            {texts.map((text, index) => (
                                <p key={index} className="typing-animation">
                                    {text}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            );
        }

        // Fallback for unsupported cases
        return <div className="text-red-500 text-center">Output format not recognized.</div>;
    };

    const renderImageOutput = (images) => {
        const imageArray = Array.isArray(images) ? images : [images];
        const handleLoad = (index) => {
            setResultImageLoading((prev) => ({ ...prev, [index]: false }));
        };

        return (
            <div className="h-full w-full">
                <div className="flex justify-between items-center bg-gray-100 mb-4 rounded-lg">
                    <h3 className="text-lg font-semibold">Result</h3>
                    {imageArray.length > 1 && (
                        <button
                            onClick={() => downloadAllImages(imageArray)}
                            className="inline-flex items-center px-4 py-2 border border-grey-300 text-sm font-medium rounded-md text-grey-700 bg-white hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                            Download All Images
                        </button>
                    )}
                </div>
                <div className="h-full w-full relative group">
                    {imageArray?.map((image, index) => (
                        <div key={index}>
                            {model.endpoint === "/print-3d" &&
                                resultImageLoading[index] !== false && (
                                    <div className="flex-grow flex items-center justify-center">
                                        <div className="text-center w-full max-w-xs">
                                            <p className="text-text-secondary mb-4">
                                                Rendering 3D Model...
                                            </p>
                                        </div>
                                    </div>
                                )}
                            {model.endpoint === "/print-3d" ? (
                                <div
                                    className="h-full w-full"
                                    style={{
                                        visibility:
                                            resultImageLoading[index] !== false
                                                ? "hidden"
                                                : "visible",
                                    }}
                                >
                                    <GLTFModel
                                        src={image}
                                        position={{ x: 0, y: -120, z: 0 }}
                                        scales={{ x: 1, y: 1, z: 1 }}
                                        onLoad={() => handleLoad(index)}
                                        width={815}
                                        height={600}
                                    >
                                        <AmbientLight color={0xffffff} />
                                        <DirectionLight
                                            color={0xffffff}
                                            position={{ x: 100, y: 200, z: 100 }}
                                        />
                                    </GLTFModel>
                                </div>
                            ) : (
                                <img
                                    src={image}
                                    alt={`Generated result ${index + 1}`}
                                    className="w-full h-full object-cover rounded-lg cursor-pointer"
                                    onClick={() => handleImageClick(image)}
                                    onLoad={() => handleLoad(index)}
                                />
                            )}

                            <div
                                className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                                style={{
                                    visibility:
                                        resultImageLoading[index] !== false ? "hidden" : "visible",
                                }}
                            >
                                <ImageActions
                                    image={image}
                                    index={index}
                                    onDownload={downloadImage}
                                    onCopyLink={copyImageLink}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderField = (field) => {
        switch (field.type) {
            case "file":
                const { getRootProps, getInputProps, isDragActive } = useDropzone({
                    onDrop: (acceptedFiles) => onDrop(acceptedFiles, field.name),
                    accept: "image/*",
                    multiple: false,
                });
                let borderClass = "border-grey-300";

                if (isDragActive) {
                    borderClass = "border-primary bg-primary bg-opacity-10";
                } else if (validationErrors[field.name]) {
                    borderClass = "border-red-500";
                }

                return (
                    <div className="col-span-2">
                        <div
                            {...getRootProps()}
                            className={`mt-1 flex justify-center items-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md h-[218px] ${borderClass} hover:border-primary transition-colors`}
                        >
                            <div className="space-y-1 text-center">
                                {files[field.name] ? (
                                    <img
                                        src={URL.createObjectURL(files[field.name])}
                                        alt="Preview"
                                        className="mx-auto h-32 w-auto object-cover rounded-md"
                                    />
                                ) : (
                                    <Upload className="mx-auto h-12 w-12 text-grey-400" />
                                )}
                                <div className="flex text-sm text-grey-600">
                                    <label
                                        htmlFor={field.name}
                                        className="relative cursor-pointer rounded-md font-medium text-primary hover:text-primary-hover focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary"
                                    >
                                        <span>
                                            {files[field.name] ? "Change file" : "Upload a file"}
                                        </span>
                                        <input
                                            {...getInputProps()}
                                            id={field.name}
                                            name={field.name}
                                            className="sr-only"
                                        />
                                    </label>
                                    {!files[field.name] && <p className="pl-1">or drag and drop</p>}
                                </div>
                                <p className="text-xs text-grey-500">PNG, JPG, GIF up to 10MB</p>
                            </div>
                        </div>
                        {validationErrors[field.name] && (
                            <p className="text-red-500 text-xs mt-1">
                                {validationErrors[field.name]}
                            </p>
                        )}
                    </div>
                );

            case "textarea":
                return (
                    <div className="col-span-2">
                        <textarea
                            name={field.name}
                            rows={4}
                            className={`mt-1 block w-full rounded-md border ${
                                validationErrors[field.name] ? "border-red-500" : "border-grey-300"
                            } shadow-sm py-2 px-3 bg-white text-text-primary focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm`}
                            required={field.required}
                            defaultValue={field.default}
                            placeholder={field.placeholder || "Enter additional details"}
                        />
                        {validationErrors[field.name] && (
                            <p className="text-red-500 text-xs mt-1">
                                {validationErrors[field.name]}
                            </p>
                        )}
                    </div>
                );

            case "select":
                return (
                    <div className="col-span-2 md:col-span-1">
                        <div className="relative">
                            <select
                                name={field.name}
                                className={`block w-full mt-1 rounded-md border ${
                                    validationErrors[field.name]
                                        ? "border-red-500"
                                        : "border-grey-300"
                                } bg-white py-2 pl-3 pr-10 text-text-primary shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm appearance-none`}
                                required={field.required}
                                defaultValue={field.default}
                            >
                                {field.options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-grey-500">
                                <svg className="h-5 w-5 fill-current" viewBox="0 0 20 20">
                                    <path
                                        fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                        {validationErrors[field.name] && (
                            <p className="text-red-500 text-xs mt-1">
                                {validationErrors[field.name]}
                            </p>
                        )}
                    </div>
                );

            default:
                return (
                    <div className="col-span-2 md:col-span-1">
                        <input
                            type={field.type}
                            name={field.name}
                            className={`mt-1 block w-full rounded-md border ${
                                validationErrors[field.name] ? "border-red-500" : "border-grey-300"
                            } shadow-sm py-2 px-3 bg-white text-text-primary focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm`}
                            required={field.required}
                            defaultValue={field.default}
                            placeholder={field.placeholder}
                            min={field.min}
                            max={field.max}
                            step={field.step || (field.type === "number" ? "any" : undefined)}
                        />
                        {validationErrors[field.name] && (
                            <p className="text-red-500 text-xs mt-1">
                                {validationErrors[field.name]}
                            </p>
                        )}
                    </div>
                );
        }
    };

    return (
        <div className="bg-white">
            <div className="mb-8 flex items-center justify-start gap-4">
                <button
                    onClick={onBack}
                    className="inline-flex items-center px-4 py-2 border border-grey-300 text-sm font-medium rounded-md text-grey-700 bg-white hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                    <ArrowLeft className="h-5 w-5" />
                </button>
                <h1 className="text-2xl font-bold text-text-primary">{model.name}</h1>
            </div>
            <div className="h-[1px] w-full bg-grey-300 mb-8"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                    <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6">
                        {model.fields.map((field) => (
                            <div
                                key={field.name}
                                className={
                                    field.width === "full"
                                        ? "col-span-2"
                                        : "col-span-2 md:col-span-1"
                                }
                            >
                                <label className="block text-sm font-medium text-text-secondary mb-1">
                                    {field.label}
                                    {field.required && <span className="text-red-500"> *</span>}
                                </label>
                                {renderField(field)}
                            </div>
                        ))}
                        <div className="col-span-2">
                            <button
                                type="submit"
                                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors"
                                disabled={loading}
                            >
                                {loading ? "Processing..." : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>

                <div className="bg-gray-100 p-6 rounded-lg min-h-[500px] flex flex-col">
                    {!result && !loading && !error && (
                        <div className="flex-grow flex items-center justify-center">
                            <div className="text-center">
                                <Wand2 className="h-16 w-16 text-primary mb-4 mx-auto" />
                                <p className="text-lg text-text-secondary">
                                    Submit the form to see the magic happen!
                                </p>
                            </div>
                        </div>
                    )}

                    {loading && (
                        <div className="flex-grow flex items-center justify-center">
                            <div className="text-center w-full max-w-xs">
                                <p className="text-text-secondary mb-4">Processing...</p>
                                <div className="relative pt-1 w-full">
                                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-300">
                                        <div
                                            style={{ width: `${progress}%` }}
                                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary transition-all duration-500 ease-out"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {error && (
                        <div className="text-danger bg-danger-light p-4 rounded-md w-full">
                            <p className="font-semibold">Error:</p>
                            <p>
                                {typeof error === "string" ? error : JSON.stringify(error, null, 2)}
                            </p>
                        </div>
                    )}

                    {!loading && !error && result && (
                        <div className="flex-grow flex">{renderResponse()}</div>
                    )}
                </div>
            </div>

            {modalImage && <ImageModal image={modalImage} onClose={() => setModalImage(null)} />}

            <Toaster position="top-center" />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx={"true"} global={"true"}>{`
                .typing-animation {
                    white-space: pre-wrap;
                    word-break: break-word;
                }

                .animated-cursor::after {
                    content: "|";
                    animation: blink 0.7s infinite;
                }

                @keyframes blink {
                    0% {
                        opacity: 0;
                    }
                    50% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
                /* .typing-animation {
          overflow: hidden;
          border-right: 0.15em solid orange;
          white-space: nowrap;
          margin: 0 auto;
          letter-spacing: 0.15em;
          animation: typing 3.5s steps(40, end),
            blink-caret 0.75s step-end infinite;
        } */

                @keyframes typing {
                    from {
                        width: 0;
                    }
                    to {
                        width: 100%;
                    }
                }

                @keyframes blink-caret {
                    from,
                    to {
                        border-color: transparent;
                    }
                    50% {
                        border-color: orange;
                    }
                }

                .lightbox-wrapper {
                    background-color: white !important;
                    color: #333 !important;
                }

                .lightbox-wrapper img {
                    object-fit: contain !important;
                    max-height: 80vh !important;
                }
            `}</style>
        </div>
    );
};

export default DynamicForm;
