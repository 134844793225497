import { upsertToArray } from "@common/utils/common-utils";
import { GenerationAction } from "../actions/generationActions";

const initialState = {
    generationPageDetails: {},
    generationHistory: [],
    totalGenerations: -1,
    pendingGenerations: 0,
};

const generationReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GenerationAction.GenerationStartSuccess: {
            return {
                ...state,
                generationHistory: [payload, ...state.generationHistory],
                pendingGenerations: state.pendingGenerations + 1,
            };
        }

        case GenerationAction.GetGenerationHistory: {
            const updatedHistory = [...state.generationHistory];
            payload.results.forEach((result) => upsertToArray(updatedHistory, result, "_id"));
            return {
                ...state,
                generationHistory: [...updatedHistory],
                totalGenerations: payload.total,
            };
        }

        case GenerationAction.GenerationUpdate: {
            const updatedHistory = state.generationHistory.map((generation) =>
                generation._id === payload._id ? { ...generation, ...payload } : generation,
            );
            return {
                ...state,
                generationHistory: updatedHistory,
            };
        }

        case GenerationAction.GenerationDeleteSuccess: {
            const updatedHistory = state.generationHistory.filter(
                (generation) => generation._id !== payload,
            );
            return {
                ...state,
                generationHistory: updatedHistory,
            };
        }

        case GenerationAction.GenerationDetailsSetSuccess: {
            return {
                ...state,
                generationPageDetails: payload,
            };
        }

        case GenerationAction.GenerationPending: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};

export default generationReducer;
