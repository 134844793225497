import LoaderIcon from "@components/LoaderIcon";
import React from "react";

const Button = ({
    as,
    children,
    type = "primary",
    size = "default", // default, small, large
    disabled = false,
    isLoading = false,
    className,
    testId = "btn",
    ...props
}) => {
    const defaultElement = "button";

    const Component = as || defaultElement;

    const buttonStyles = {
        primary: {
            base: "bg-primary border border-transparent focus:border-grey-100 outline-none",
            active: "active:bg-primary-dark",
            disabled: "disabled:bg-opacity-50 disabled:text-white/50 cursor-not-allowed",
            size: {
                small: `${isLoading ? "px-4 py-1" : "px-3 py-2"} text-button-small`,
                default: `${isLoading ? "px-7" : "px-4"} py-2 text-button-medium`,
                large: `${isLoading ? "px-9 py-2" : "px-6 py-3"} text-button-large`,
            },
            icon: {
                small: { wrapper: "h-6 w-6 fill-white", icon: "h-6 w-6" },
                default: { wrapper: "h-6 w-6 fill-white", icon: "h-6 w-6" },
                large: { wrapper: "h-8 w-8 fill-white", icon: "h-8 w-8" },
            },
        },
        secondary: {
            base: "bg-transparent border border-primary focus:border-grey-100 outline-none",
            active: "active:border-primary-dark",
            disabled: "disabled:border-opacity-50 disabled:text-white/50 cursor-not-allowed",
            size: {
                small: `${isLoading ? "px-4 py-1" : "px-3 py-2"} text-button-small`,
                default: `${isLoading ? "px-7" : "px-4"} py-2 text-button-medium`,
                large: `${isLoading ? "px-9 py-2" : "px-6 py-3"} text-button-large`,
            },
            icon: {
                small: { wrapper: "h-6 w-6 fill-white", icon: "h-6 w-6" },
                default: { wrapper: "h-6 w-6 fill-white", icon: "h-6 w-6" },
                large: { wrapper: "h-8 w-8 fill-white", icon: "h-8 w-8" },
            },
        },
    };

    const baseStyle = buttonStyles[type].base;
    const buttonSize = buttonStyles[type].size[size];
    const buttonHover = isLoading ? "" : "enabled:hover:shadow-button";
    const buttonActive = !(disabled || isLoading) ? buttonStyles[type].active : "";
    const cursorLoading = isLoading ? "cursor-progress" : "";
    const disableBg = disabled && !isLoading ? buttonStyles[type].disabled : "";

    const buttonActiveCustomComponent = !(disabled || isLoading) ? "active:bg-primary-dark" : "";
    const buttonHoverCustomComponent = isLoading ? "" : "hover:shadow-button";
    const disableBgCustomComponent =
        disabled && !isLoading ? "bg-opacity-50 text-white/50 cursor-not-allowed" : "";

    // Render the button with computed classes and props

    const getButtonClasses = () => {
        if (as) {
            return disabled
                ? disableBgCustomComponent
                : `${buttonActiveCustomComponent} ${buttonHoverCustomComponent}`;
        }
        return `${disableBg} ${buttonHover} ${buttonActive}`;
    };

    return (
        <Component
            data-testid={testId}
            className={`flex justify-center items-center rounded-8 font-semibold ${baseStyle} ${getButtonClasses()} ${buttonSize} ${cursorLoading} ${className}`}
            disabled={disabled || isLoading}
            {...props}
        >
            {isLoading ? <LoaderIcon className={buttonStyles[type].icon[size]} /> : children}
        </Component>
    );
};

export default Button;
